import emailjs from "emailjs-com";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

// const meta = {
//   title:
//     "MaraHilltop - Safari",
//   meta: [
//     {
//       name: "description",
//       content:
//         "Experience the ultimate safari adventure in Kenya. Discover our luxury lodges, hostels, and camps set in the heart of African wilderness.",
//     },
//     {
//       name: "keywords",
//       content:
//         "safari, African safari, Kenya, wildlife, adventure, camping, lodge, hostel, travel, nature, wilderness",
//     },
//     {
//       property: "og:title",
//       content:
//         "MaraHilltop - Safari",
//     },
//     {
//       property: "og:description",
//       content:
//         "Join us for an unforgettable experience in Kenya, exploring the wild and staying in our top-rated safari lodges and hostels.",
//     },
//     { property: "og:type", content: "website" },
//     { property: "og:image", content: "https://marahilltop.com/asitrastudio-assets/headers/center-bottom-photo3.png" },

//     { property: "og:url", content: "https://marahilltop.com/" },
//     { name: "twitter:card", content: "summary_large_image" },
//     {
//       name: "twitter:title",
//       content:
//         "MaraHilltop - Safari",
//     },
//     {
//       name: "twitter:description",
//       content:
//         "Experience the best of African safaris in Kenya. Luxury lodges, immersive wildlife experiences, and beautiful landscapes await you.",
//     },
//     { property: "twitter:image", content: "https://marahilltop.com/asitrastudio-assets/headers/center-bottom-photo3.png" },

//   ],
//   link: [],
//   style: [],
//   script: [],
// };

export default function Index() {
  const [email, setEmail] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [subscriptionMessage, setSubscriptionMessage] = useState("");

  const texts = ["Solo-Travelers", "Backpackers", "Digital Nomads"];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % texts.length);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    window.mailchimpCallback = (response) => {
      if (response.result === "success") {
        setSubscriptionMessage(
          "Thanks for your interest! We’ll let you know when we’re ready."
        );
        setTimeout(() => {
          setSubscriptionMessage("");
        }, 3000);
      } else {
        setSubscriptionMessage(
          "There was an error subscribing. Please try again."
        );
      }
      setEmail("");
    };

    return () => {
      delete window.mailchimpCallback;
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const MAILCHIMP_URL =
      "https://wipiway.us21.list-manage.com/subscribe/post-json?u=9deda5630437d6eb76bea2fcc&id=371d1be022";
    const SERVICE_ID = "service_to4tuah";
    const TEMPLATE_ID = "template_d85hvp6";
    const USER_ID = "i_-ybnIfdpUvzqJMi";

    const script = document.createElement("script");
    script.src = `${MAILCHIMP_URL}&EMAIL=${encodeURIComponent(
      email
    )}&c=mailchimpCallback`;
    document.body.appendChild(script);
    const templateParams = {
      to_email: "nj@marahilltop.com",
      user_email: email,
      message: "A new user has subscribed: " + email,
    };
    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID).then(
      (response) => {
        console.log("Email sent!", response.status, response.text);
      },
      (error) => {
        console.log("Failed to send email.", error);
      }
    );
  };

  const scrollToSubscribe = () => {
    document
      .getElementById("subscribe-section")
      .scrollIntoView({ behavior: "smooth" });
  };

  const [style, setStyle] = useState({
    fontSize: "6.5rem",
    margin: "0 2rem",
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setStyle({
          fontSize: "4.5rem",
          margin: "0 1rem",
        });
      } else if (window.matchMedia("(max-width: 1024px)").matches) {
        setStyle({
          fontSize: "5.5rem",
          margin: "0 1.5rem",
        });
      } else {
        setStyle({
          fontSize: "6.5rem",
          margin: "0 2rem",
        });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <React.Fragment>
      {/* <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider> */}
      <>
        <section className="relative bg-coolGray-50">
          <nav>
            <div className="container px-4 mx-auto">
              <div className="flex items-center h-24">
                <a className="inline-block" href="#">
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "24px",
                    }}
                  >
                    MARA HILLTOP
                  </p>
                </a>
                <button className="py-1 ml-auto lg:hidden navbar-burger">
                  <svg
                    width={44}
                    height={16}
                    viewBox="0 0 44 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width={44} height={2} rx={1} fill="black" />
                    <rect y={14} width={44} height={2} rx={1} fill="black" />
                  </svg>
                </button>
                <div className="items-center hidden ml-auto mr-12 lg:flex"></div>
                <a
                  className="items-center justify-center hidden px-8 py-4 font-medium leading-none text-black transition duration-200 border border-black rounded-full group lg:inline-flex hover:text-white hover:bg-black"
                  href="#contact_id"
                >
                  <span onClick={scrollToSubscribe} className="mr-2">
                    Contact
                  </span>
                  <span className="transition duration-100 transform group-hover:rotate-45">
                    <svg
                      width={10}
                      height={10}
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 1L1 9"
                        stroke="currentColor"
                        strokeWidth="1.3"
                        strokeMiterlimit={10}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9 8.33571V1H1.66429"
                        stroke="currentColor"
                        strokeWidth="1.3"
                        strokeMiterlimit={10}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </nav>

          <div className="container relative px-4 pb-64 mx-auto pt-14 lg:pb-28">
            <img
              className="absolute left-0 block object-cover transform -translate-y-0 w-50 h-80 rounded-4xl xs:block top-1/4 w-28 sm:w-40 lg:w-auto"
              src="asitrastudio-assets/headers/center-bottom-photo3.png"
              alt=""
            />
            <div
              className="relative max-w-sm mx-auto md:max-w-xl xl:max-w-5xl"
              style={{ top: "10px" }}
            >
              <div className="text-center">
                <h1
                  style={style}
                  className="text-6xl font-heading md:text-13xl xl:text-14xl tracking-tightest"
                >
                  Masai Mara, <br /> unlocked for <br />
                  <div className="animated-text-container">
                    <span key={texts[currentIndex]} className="animated-text">
                      {texts[currentIndex]}
                    </span>
                  </div>
                </h1>
                <p className="text-xl text-coolGray-600">
                  Opening soon. July 2024
                </p>
              </div>
            </div>
            <div className="max-w-sm ml-auto text-right mt-26">
              {/* <span className="block mb-1 text-xs">Since</span>
              <span className="block mb-3 text-xs">1979</span> */}
              <p>
                <span className="block">
                  Perfect Location.
                  <br /> Located just 5 mins from the main Sekenani Gate up on a
                  hill on the left,
                </span>
                <span className="block">
                  Mara Hilltop is your perfect base for the epic Mara Safari -
                  Amazing sunsets - wildlife all around - local Masai touch.
                </span>
              </p>
            </div>
            <div className="absolute bottom-0 pb-10 transform -translate-x-1/2 left-1/2">
              {/* <div className="absolute top-0 right-0 flex items-center justify-center w-40 h-40 mr-5 -mt-24">
                <img
                  className="absolute top-0 left-0 animate-spinSlow"
                  style={{ animationDirection: "reverse" }}
                  src="asitrastudio-assets/headers/spin-bonus.svg"
                  alt=""
                />
                <img
                  className="relative"
                  src="asitrastudio-assets/headers/arrow-spin.svg"
                  alt=""
                />
              </div> */}
              <img
                className="object-cover h-40 rounded-4xl"
                src="asitrastudio-assets/headers/center-bottom-photo.png"
                alt=""
              />
            </div>
            <img
              // className="absolute top-0 right-0 hidden w-40 mt-20 md:block lg:w-52 xl:w-auto xl:mt-24"
              className="absolute top-0 right-0 block object-cover w-50 h-80 rounded-4xl"
              src="asitrastudio-assets/headers/right-photo-rounded1.png"
              alt=""
            />
          </div>
          <div className="fixed top-0 bottom-0 left-0 z-50 hidden w-5/6 max-w-md navbar-menu">
            <div className="fixed inset-0 bg-gray-800 opacity-50 navbar-backdrop" />
            <nav className="relative flex flex-col w-full h-full px-10 py-6 overflow-y-auto bg-white">
              <div className="flex items-center mb-auto">
                <a className="inline-block mr-auto" href="#">
                  <img
                    className="h-4"
                    src="asitrastudio-assets/logos/logo-asi.svg"
                    alt=""
                  />
                </a>
                <a className="navbar-close" href="#">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 18L18 6M6 6L18 18"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
              <div className="py-12 mb-auto">
                <ul className="flex-col">
                  <li className="mb-6">
                    <a className="inline-block text-black" href="#">
                      About
                    </a>
                  </li>
                  <li className="mb-6">
                    <a className="inline-block text-black" href="#">
                      Services
                    </a>
                  </li>
                  <li className="mb-6">
                    <a className="inline-block text-black" href="#">
                      Projects
                    </a>
                  </li>
                  <li>
                    <a className="inline-block text-black" href="#">
                      Contact us
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className="block px-4 py-4 mb-3 font-medium text-center text-black transition duration-200 border border-black rounded-full hover:text-white hover:bg-black"
                  href="#"
                >
                  Login
                </a>
                <a
                  className="block px-4 py-4 font-medium text-center text-white transition duration-200 bg-blue-500 rounded-full hover:bg-blue-600"
                  href="#"
                >
                  Sign in
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section class="flex justify-center my-20" id="subscribe-section">
          <div class="container px-4 mx-auto">
            <form
              id="subscribe-form"
              class="w-full max-w-md mx-auto"
              onSubmit={handleSubmit}
            >
              <div class="mb-4">
                <label class="block text-sm mb-2 text-center" for="field-name">
                  Keep me updated
                </label>
                <input
                  class="block w-full py-2 text-coolGray-500 bg-white border-1.5 border-coolGray-400 rounded-full text-center"
                  type="email"
                  name="EMAIL"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button
                class="inline-block w-full py-4 text-center font-medium text-white leading-none bg-black hover:bg-black-600 rounded-full transition duration-1500"
                type="submit"
              >
                Notify Me
              </button>
            </form>
            {subscriptionMessage && (
              <div className="mt-6 text-center text-green-600">
                <p>{subscriptionMessage}</p>
              </div>
            )}
          </div>
        </section>
        <section className="py-12 overflow-hidden md:py-24 bg-coolGray-50">
          <div className="container px-4 mx-auto">
            <h1 className="mb-20 text-6xl tracking-tighter font-heading xs:text-8xl sm:text-10xl">
              What we offer
            </h1>
            <div className="max-w-2xl mx-auto xl:max-w-none">
              <div className="flex flex-wrap -mx-1.5">
                <div className="w-full xl:w-1/2 px-1.5 mb-4 xl:mb-0">
                  <div className="h-full border rounded-4xl">
                    <img
                      className="block object-cover w-full h-64 mb-16 rounded-4xl"
                      src="asitrastudio-assets/services/photo-left.png"
                      alt=""
                    />
                    <div className="max-w-lg px-6 pb-16 mx-auto text-center">
                      <span className="block mb-4 text-4xl">
                        Luxury Tents and Dorms
                      </span>

                      <p className="mb-8">
                        At Mara Hilltop, we blend comfort with nature. Our
                        accommodation options include 10 luxury tents, 10
                        regular tents, and specially designed dorms, all within
                        a 3km / 5 min drive from the main Mara gate. Surrounded
                        by three conservancies, guests enjoy an abundance of
                        wildlife and serene nature, free from pollution. We
                        prioritize an eco-friendly approach, following the RIBA
                        Plan of Work 2020 for sustainable and responsible
                        tourism.
                      </p>
                      <a
                        className="inline-flex items-center pb-2 font-medium leading-none border-b-2 border-black group"
                        href="#"
                      ></a>
                    </div>
                  </div>
                </div>
                <div className="w-full xl:w-1/2 px-1.5">
                  <a className="block mb-3 border group rounded-4xl" href="#">
                    <div className="flex flex-wrap justify-between">
                      <div className="self-center w-full py-0 pl-6 pr-5 md:w-7/12 sm:pl-12">
                        <div className="max-w-xs py-18">
                          <span className="block mb-4 text-4xl">
                            Cowork in Nature
                          </span>

                          <p>
                            Mara Hilltop is the ideal coworking space for
                            digital nomads and influencers. With premium routers
                            for stable internet, coworking desks, and couches
                            with charging plugs, we cater to all your work
                            needs. Our location offers a unique opportunity to
                            work amidst the breathtaking beauty of Mara nature,
                            encouraging creativity and productivity.
                          </p>
                        </div>
                      </div>
                      <div className="w-full transition duration-500 md:w-5/12 md:transform group-hover:translate-x-4">
                        <img
                          className="block object-cover w-full h-full rounded-4xl"
                          src="asitrastudio-assets/services/photo-left1.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </a>
                  <a className="block border group rounded-4xl" href="#">
                    <div className="flex flex-wrap justify-between">
                      <div className="self-center w-full py-0 pl-6 pr-5 md:w-7/12 sm:pl-12">
                        <div className="max-w-xs py-18">
                          <span className="block mb-4 text-4xl">
                            Environment Friendly
                          </span>

                          <p>
                            Committed to sustainability, Mara Hilltop follows
                            the RIBA Plan of Work 2020. Our setup includes
                            eco-friendly accommodations and energy solutions
                            like solar panels and backup generators. We are
                            devoted to minimizing our environmental impact while
                            offering a premium, nature-immersed experience for
                            our guests.
                          </p>
                        </div>
                      </div>
                      <div className="w-full transition duration-500 md:w-5/12 md:transform group-hover:translate-x-4">
                        <img
                          className="block object-cover w-full h-full rounded-4xl"
                          src="asitrastudio-assets/services/half-photo-btn4.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="py-12 md:py-20 bg-coolGray-50">
          <div class="container mx-auto px-4" id="contact_id">
            <div class="flex flex-wrap -mx-4 justify-center">
              <div class="w-full lg:w-1/2 px-4 mb-16 lg:mb-0 text-center">
                <div class="max-w-xl mx-auto lg:mx-0">
                  <div class="max-w-lg mb-10">
                    <h1 class="font-heading text-6xl xs:text-8xl sm:text-9xl xl:text-10xl tracking-tighter mb-8">
                      Contact Us
                    </h1>
                    {/* <p class="text-xl text-coolGray-600">
                      Whereby Embedded is the easiest way to embed video calls
                      directly into your app, website, or anywhere else you need
                      beautiful, custom video chat – that just works.
                    </p> */}
                  </div>
                  <div class="flex flex-wrap -mx-4 mb-20">
                    <div class="w-full sm:w-1/2 px-4 mb-6 sm:mb-0">
                      <div class="py-6 text-center border border-coolGray-300 rounded-3xl min-h-[10rem]">
                        <span class="block mb-2 text-sm text-coolGray-500">
                          Call us
                        </span>
                        <span class="font-medium">
                          Kenya: +254 788 880088
                          <br />
                        </span>
                        <span class="font-medium">US: +1 717 683 9393</span>
                      </div>
                    </div>
                    <div class="w-full sm:w-1/2 px-4">
                      <div class="py-6 text-center border border-coolGray-300 rounded-3xl min-h-[10rem]">
                        <span class="block mb-2 text-sm text-coolGray-500">
                          Email us
                        </span>
                        <span class="font-medium">hello@marahilltop.com</span>
                      </div>
                    </div>
                  </div>

                  <div class="mb-16">
                    <span class="block mb-5 font-medium text-lg">Socials</span>
                    <div class="flex flex-wrap items-center justify-center text-center">
                      <a
                        class="inline-flex items-center hover:underline font-medium"
                        href="#"
                      >
                        <svg
                          width="19"
                          height="19"
                          viewbox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.0038 4.8916C7.7351 4.8916 5.89844 6.73027 5.89844 8.99694C5.89844 11.2656 7.7371 13.1023 10.0038 13.1023C12.2724 13.1023 14.1091 11.2636 14.1091 8.99694C14.1091 6.72827 12.2704 4.8916 10.0038 4.8916ZM10.0038 11.6616C8.5311 11.6616 7.3391 10.4689 7.3391 8.99694C7.3391 7.52494 8.53177 6.33227 10.0038 6.33227C11.4758 6.33227 12.6684 7.52494 12.6684 8.99694C12.6691 10.4689 11.4764 11.6616 10.0038 11.6616Z"
                            fill="black"
                          ></path>
                          <path
                            d="M13.2988 1.05026C11.8268 0.981591 8.18082 0.984924 6.70749 1.05026C5.41282 1.11092 4.27082 1.42359 3.35015 2.34426C1.81148 3.88292 2.00815 5.95626 2.00815 8.99692C2.00815 12.1089 1.83482 14.1343 3.35015 15.6496C4.89482 17.1936 6.99815 16.9916 10.0028 16.9916C13.0855 16.9916 14.1495 16.9936 15.2395 16.5716C16.7215 15.9963 17.8402 14.6716 17.9495 12.2923C18.0188 10.8196 18.0148 7.17426 17.9495 5.70092C17.8175 2.89226 16.3102 1.18892 13.2988 1.05026ZM15.6288 14.6316C14.6202 15.6403 13.2208 15.5503 9.98349 15.5503C6.65015 15.5503 5.31348 15.5996 4.33815 14.6216C3.21482 13.5036 3.41815 11.7083 3.41815 8.98626C3.41815 5.30292 3.04015 2.65026 6.73682 2.46092C7.58615 2.43092 7.83615 2.42092 9.97415 2.42092L10.0042 2.44092C13.5568 2.44092 16.3442 2.06892 16.5115 5.76492C16.5495 6.60826 16.5582 6.86159 16.5582 8.99626C16.5575 12.2909 16.6202 13.6356 15.6288 14.6316Z"
                            fill="black"
                          ></path>
                          <path
                            d="M14.2718 5.6882C14.8017 5.6882 15.2312 5.25869 15.2312 4.72886C15.2312 4.19904 14.8017 3.76953 14.2718 3.76953C13.742 3.76953 13.3125 4.19904 13.3125 4.72886C13.3125 5.25869 13.742 5.6882 14.2718 5.6882Z"
                            fill="black"
                          ></path>
                        </svg>
                        <span class="ml-3">Instagram</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
